import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section, Strong, Icon, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { IoIosCheckmarkCircle } from "react-icons/io";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | CodeCrafters Hub
			</title>
			<meta name={"description"} content={"Творим будущее с каждой строчкой кода"} />
			<meta property={"og:title"} content={"Home | CodeCrafters Hub"} />
			<meta property={"og:description"} content={"Творим будущее с каждой строчкой кода"} />
			<meta property={"og:image"} content={"https://qwicksi.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://qwicksi.com/img/4260948.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://qwicksi.com/img/4260948.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://qwicksi.com/img/4260948.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://qwicksi.com/img/4260948.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://qwicksi.com/img/4260948.png"} />
			<meta name={"msapplication-TileImage"} content={"https://qwicksi.com/img/4260948.png"} />
		</Helmet>
		<Components.Header />
		<Section
			background="linear-gradient(0deg,rgba(255, 255, 255, 0.6) 0%,rgba(255, 255, 255, 0.6) 100%),url(https://qwicksi.com/img/1.jpg) center center/cover no-repeat"
			min-height="100vh"
			lg-padding="140px 0 88px 0"
			quarkly-title="Hero-21"
			padding="88px 0 88px 0"
		>
			<Override
				slot="SectionContent"
				flex-direction="column"
				flex-wrap="wrap"
				align-items="center"
				justify-content="center"
			/>
			<Text
				letter-spacing="2px"
				margin="0px 0px 16px 0px"
				color="--greyD2"
				font="--base"
				lg-width="100%"
				lg-text-align="center"
				text-align="center"
				text-transform="uppercase"
			>
				Добро пожаловать
			</Text>
			<Text
				color="--darkL1"
				font="--headline1"
				sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				margin="0px 0px 42px 0px"
				lg-width="100%"
				md-width="100%"
				lg-text-align="center"
				lg-margin="0px 0px 20px 0px"
				lg-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				text-align="center"
				letter-spacing="30px"
			>
				CodeCrafters Hub
			</Text>
			<Text
				lg-text-align="center"
				text-align="center"
				width="70%"
				margin="0px 0px 36px 0px"
				color="--darkL2"
				font="--lead"
				lg-width="100%"
			>
				Здесь, в мире, где каждая строчка кода открывает новые возможности, мы предлагаем вам обучение, которое превзойдет все ваши ожидания. Наши курсы охватывают широкий спектр направлений – от QA и Python до разработки игр, фронтенд-разработки, дизайна UX/UI в Figma, управления проектами и HR. Мы приглашаем вас на путь к мастерству, где теория встречается с практикой в реальных проектах, обеспечивая глубокое и всестороннее понимание предмета.
			</Text>
			<Box display="flex" sm-flex-direction="column">
				<Link
					color="--light"
					font="--lead"
					sm-margin="0px 0px 20px 0px"
					sm-width="100%"
					hover-color="--dark"
					border-width="2px"
					border-style="solid"
					href="/contact"
					text-decoration-line="initial"
					border-radius="75px"
					background="--color-dark"
					border-color="--color-dark"
					padding="10px 53px 10px 53px"
					margin="0px 24px 0px 0px"
					transition="background-color 0.3s linear 0s"
					hover-background="rgba(4, 8, 12, 0)"
					sm-text-align="center"
				>
					Контакты
				</Link>
			</Box>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			display="flex"
			lg-padding="45px 0 45px 0"
			quarkly-title="Advantages/Features-18"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				max-width="1200px"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				align-items="center"
				lg-width="100%"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				lg-justify-content="center"
				sm-justify-content="flex-start"
			>
				<Text
					margin="8px 0px 0px 0px"
					font="--lead"
					text-align="center"
					color="--green"
					sm-text-align="left"
				>
					<Strong color="--primary">
					О Нас
					</Strong>
				</Text>
				<Text
					as="h1"
					margin="4px 0px 12px 0px"
					font="--headline2"
					color="--darkL2"
					width="100%"
					md-width="100%"
					text-align="center"
					sm-text-align="left"
				>
					Почему стоит выбрать CodeCrafters Hub?
				</Text>
				<Box
					display="grid"
					width="100%"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="24px"
					margin="60px 0px 0px 0px"
					max-width="1000px"
					md-grid-template-columns="1fr"
					md-grid-gap="6px"
					sm-grid-gap="32px"
				>
					<Box
						padding="24px 24px 48px 24px"
						border-radius="8px"
						display="flex"
						sm-padding="0px 0px 0px 0px"
						md-padding="24px 24px 24px 24px"
						sm-flex-direction="column"
					>
						<Icon
							category="io"
							icon={IoIosCheckmarkCircle}
							size="42px"
							color="--light"
							margin="0px 24px 0px 0px"
							background="--color-primary"
							padding="8px 8px 8px 8px"
							border-radius="8px"
							sm-margin="0px 0px 12px 0px"
						/>
						<Box>
							<Text
								font="--lead"
								color="--darkL2"
								margin="0px 0px 0px 0px"
								letter-spacing="0.3px"
								sm-margin="4px 0px 0px 0px"
							>
								<Strong>
								Реальные проекты
								</Strong>
							</Text>
							<Text margin="16px 0px 0px 0px" font="--base" color="--darkL1" sm-margin="6px 0px 0px 0px">
							Наши курсы наполнены задачами из реального мира, что делает обучение максимально приближенным к практике.
							</Text>
						</Box>
					</Box>
					<Box
						padding="24px 24px 48px 24px"
						border-radius="8px"
						display="flex"
						sm-padding="0px 0px 0px 0px"
						md-padding="24px 24px 24px 24px"
						sm-flex-direction="column"
					>
						<Icon
							category="io"
							icon={IoIosCheckmarkCircle}
							size="42px"
							color="--light"
							margin="0px 24px 0px 0px"
							background="--color-primary"
							padding="8px 8px 8px 8px"
							border-radius="8px"
							sm-margin="0px 0px 12px 0px"
						/>
						<Box>
							<Text
								font="--lead"
								color="--darkL2"
								margin="0px 0px 0px 0px"
								letter-spacing="0.3px"
								sm-margin="4px 0px 0px 0px"
							>
								<Strong>
								Инновационный подход
								</Strong>
							</Text>
							<Text margin="16px 0px 0px 0px" font="--base" color="--darkL1" sm-margin="6px 0px 0px 0px">
							Мы сочетаем проверенные методики с новаторскими решениями в образовании, чтобы сделать ваше обучение захватывающим и эффективным.
							</Text>
						</Box>
					</Box>
					<Box
						padding="24px 24px 48px 24px"
						border-radius="8px"
						display="flex"
						sm-padding="0px 0px 0px 0px"
						md-padding="24px 24px 24px 24px"
						sm-flex-direction="column"
					>
						<Icon
							category="io"
							icon={IoIosCheckmarkCircle}
							size="42px"
							color="--light"
							margin="0px 24px 0px 0px"
							background="--color-primary"
							padding="8px 8px 8px 8px"
							border-radius="8px"
							sm-margin="0px 0px 12px 0px"
						/>
						<Box>
							<Text
								font="--lead"
								color="--darkL2"
								margin="0px 0px 0px 0px"
								letter-spacing="0.3px"
								sm-margin="4px 0px 0px 0px"
							>
								<Strong>
								Открытость для каждого
								</Strong>
							</Text>
							<Text margin="16px 0px 0px 0px" font="--base" color="--darkL1" sm-margin="6px 0px 0px 0px">
							Наша миссия – сделать обучение доступным, предлагая курсы для любого уровня подготовки и графика занятий.
							</Text>
						</Box>
					</Box>
					<Box
						padding="24px 24px 48px 24px"
						border-radius="8px"
						display="flex"
						sm-padding="0px 0px 0px 0px"
						md-padding="24px 24px 24px 24px"
						sm-flex-direction="column"
					>
						<Icon
							category="io"
							icon={IoIosCheckmarkCircle}
							size="42px"
							color="--light"
							margin="0px 24px 0px 0px"
							background="--color-primary"
							padding="8px 8px 8px 8px"
							border-radius="8px"
							sm-margin="0px 0px 12px 0px"
						/>
						<Box>
							<Text
								font="--lead"
								color="--darkL2"
								margin="0px 0px 0px 0px"
								letter-spacing="0.3px"
								sm-margin="4px 0px 0px 0px"
							>
								<Strong>
								Путь к мастерству
								</Strong>
							</Text>
							<Text margin="16px 0px 0px 0px" font="--base" color="--darkL1" sm-margin="6px 0px 0px 0px">
							Подписка на нашу рассылку – это ваш шаг к постоянному развитию и обучению.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-4">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				width="65%"
				justify-content="center"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				lg-width="100%"
				padding="16px 16px 16px 16px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 125% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						bottom={0}
						min-height="100%"
						top={0}
						left={0}
						position="absolute"
						display="block"
						width="100%"
						right={0}
						src="https://qwicksi.com/img/2.jpg"
						object-fit="cover"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				align-items="flex-start"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				width="35%"
				flex-direction="column"
				lg-align-items="center"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					padding="0px 0px 65% 0px"
					width="100%"
					height="auto"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						right={0}
						bottom="0px"
						object-fit="cover"
						position="absolute"
						display="block"
						width="100%"
						top="auto"
						min-height="100%"
						src="https://qwicksi.com/img/3.jpg"
					/>
				</Box>
				<Text
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					margin="24px 0px 48px 0px"
					md-text-align="left"
				>
					CodeCrafters Hub – Начните свой путь к успеху в программировании вместе с нами
				</Text>
				<Box
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					padding="0px 0px 65% 0px"
				>
					<Image
						display="block"
						width="100%"
						top="auto"
						bottom="0px"
						min-height="100%"
						src="https://qwicksi.com/img/4.jpg"
						object-fit="cover"
						position="absolute"
						left={0}
						right={0}
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});